<template>
  <div>
    <b-overlay :show="loading">
      <div class="container">
        <p>{{ link }}</p>
        <b-btn variant="primary" @click="doCopy" size="sm">
          <feather-icon icon="CopyIcon" class="mr-50" />
          <span class="align-middle">คัดลอก</span>
        </b-btn>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ToastificationContent,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      link: '',
      loading: false,
    }
  },
  watch: {
    data(val) {
      this.generateLinkPayment(val)
    },
  },
  methods: {
    async generateLinkPayment(val) {
      // console.log('generateLinkPayment', val)
      if (!val) {
        this.loading = false
        this.link = ''
        return
      }

      this.loading = true
      const res = await this.api.post('api/supports/strip/promptPay', {
        user_id: val.customer.id,
        amount: Number(val.credit),
      })
      if (res) {
        this.link = res
      }
      // console.log('res', res)
      this.loading = false
    },
    doCopy() {
      if (!this.link) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'ไม่พบลิ้งค์ที่ต้องการคัดลอก',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.$copyText(this.link).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'คัดลอกแล้ว',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  background: #f8f8f8;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  flex-wrap: wrap;
  p {
    margin: 0;
    word-break: break-all;
  }
}
</style>
