<template>
  <div>
    <b-overlay :show="loading" no-wrap />
    <form-wizard
      ref="ref-tab-add-credit"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="เริ่มต้นใหม่"
      back-button-text="ย้อนกลับ"
      next-button-text="ต่อไป"
      class="mb-3"
      validateOnBack
      @on-complete="onComplete"
    >
      <tab-content title="สร้างสลิป" :before-change="validateStepOne">
        <step-one ref="stepOneRef" :customer="customer" />
      </tab-content>
      <tab-content title="รับสลิป">
        <step-two :data="data" />
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import StepOne from './components/StepOne.vue'
import StepTwo from './components/StepTwo.vue'

export default {
  components: { FormWizard, TabContent, StepOne, StepTwo },
  data() {
    return {
      loading: false,
      customer: null,
      data: null,
    }
  },
  created() {
    const { data } = this.$route.params
    // console.log('data', data)
    if (data) {
      this.customer = { ...data }
    }
  },
  methods: {
    async validateStepOne() {
      const validate = await this.$refs.stepOneRef.validateForm()
      if (!validate) return false

      this.data = JSON.parse(JSON.stringify(this.$refs.stepOneRef.getForm()))

      return true
    },
    onComplete() {
      this.customer = null
      this.data = null
      this.$refs.stepOneRef.resetForm()
      this.$refs['ref-tab-add-credit'].reset()
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-wizard.scss';
</style>
