<template>
  <div>
    <!-- <pre>{{ form }}</pre> -->
    <validation-observer ref="add-credit-form-ref">
      <b-form>
        <b-row cols="1">
          <b-col>
            <b-form-group label="จำนวนเครดิต (ขั้นต่ำ 10 บาท)" label-for="credit">
              <validation-provider #default="{ errors }" name="credit" rules="required">
                <b-form-input
                  v-model="_credit"
                  :state="errors.length > 0 ? false : null"
                  placeholder="ระบุจำนวนเครดิตที่ต้องการเติม"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <label for="customerSearch">ค้นหาลูกค้า</label>
            <b-form-input v-model="search" name="customerSearch" placeholder="ระบุชื่อหรือเบอร์มือถือของลูกค้า" />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-overlay :show="loading">
      <b-list-group class="mt-1" v-if="customers.length > 0">
        <div
          v-for="customer in customers"
          :key="customer.id"
          class="customer-list-item"
          @click="selectCustomer(customer)"
        >
          <b-list-group-item :active="checkListItemActive(customer)" button>
            <b-row>
              <b-col sm="12" md="6">
                <h5>อีเมล / เบอร์มือถือ</h5>
                <p>{{ customer.name }}</p>
              </b-col>
              <b-col>
                <h5>เครดิตคงเหลือ</h5>
                <p>{{ formatNumberToCurrency(customer.credit_sandbox) }} บาท</p>
              </b-col>
              <b-col>
                <h5>สมัครจาก</h5>
                <p>{{ customer.provider }}</p>
              </b-col>
            </b-row>
          </b-list-group-item>
        </div>
      </b-list-group>
      <div v-else class="text-center mt-4">
        <div v-if="!loading">
          <p v-if="search">ไม่พบข้อมูลลูกค้าที่ค้นหา</p>
          <p v-else>โปรดค้นหาลูกค้าที่ต้องการสร้างสลิป</p>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import _ from 'lodash-es'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, minValue } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    minValue,
    ToastificationContent,
  },
  props: {
    customer: Object,
  },
  data() {
    return {
      search: '',
      customers: [],
      debounceSearch: null,
      form: {
        customer: null,
        credit: 0,
      },
      loading: false,
    }
  },
  computed: {
    _credit: {
      get() {
        return this.formatNumberToCurrency(this.form.credit ?? 0)
      },
      set(val) {
        // if (!val) {
        //   this.form.credit = NaN
        //   return
        // }

        const v = `${val}`.replace(/,/g, '')
        if (!Number(v)) {
          this.form.credit = 0
        } else {
          this.form.credit = Number(v)
        }
      },
    },
  },
  watch: {
    search(newVal) {
      this.debounceSearch(newVal)
    },
    customers(newVal) {
      if ([...newVal].length === 1) {
        this.form.customer = [...newVal][0]
      }
    },
  },
  created() {
    this.debounceSearch = _.debounce(this.callToApi, 500)
  },
  mounted() {
    // console.log('test', this.customer)
    if (this.customer) {
      this.customers = [{ ...this.customer }]
    }
  },
  beforeDestroy() {
    this.debounceSearch.cancel()
  },
  methods: {
    async callToApi(newVal) {
      // console.log('call', newVal)
      if (this.form.customer) {
        this.form.customer = null
      }
      if (!newVal) {
        this.loading = false
        this.customers = []
        return
      }
      this.loading = true
      const res = await this.api.get(`api/supports/client/search/${newVal}`).catch(() => null)
      // console.log('res', res)
      if (res) {
        this.customers = [...res]
      } else {
        this.customers = []
      }

      this.loading = false
    },
    selectCustomer(val) {
      this.form.customer = val
    },
    checkListItemActive(val) {
      if (this.form.customer?.id === val.id) return true

      return false
    },
    test(val) {
      if (!val || Number(val) < 10) {
        this.form.credit = 10
      } else {
        this.form.credit = Number(val).toFixed()
      }
    },
    async validateForm() {
      const validate = await this.$refs['add-credit-form-ref'].validate()
      if (!validate) return false

      if (this.form.credit < 10) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'จำนวนเครดิตขั้นต่ำ 10 บาท',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
        return false
      }

      if (!this.form.customer) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'โปรดเลือกลูกค้าที่ต้องการสร้างสลิป',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
        return false
      }

      return true
    },
    getForm() {
      return this.form
    },
    resetForm() {
      this.form = {
        customer: null,
        credit: 0,
      }
      this.$refs['add-credit-form-ref'].reset()
      this.customers = []
      this.search = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.customer-list-item {
  border-radius: 0.2rem;
  margin-bottom: 0.5rem;
}
</style>
